module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"thedevseb_portfolio","short_name":"thedevseb_portfolio","start_url":"/","theme_color":"#1C2430","background_color":"#1C2430","display":"standalone","icon":"src/images/global/favicon.png","cache_busting_mode":"query","icon_options":{"purpose":"any maskable"},"include_favicon":true,"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cacheDigest":"acb1aa74b51a9bc0a014ec168323296c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
